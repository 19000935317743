import { format } from "date-fns"

import {
  LineItemEntityType,
  TEstimate,
  UTCSeconds,
} from "src/components/Account/types"
import { TAvailablePlan } from "src/data/billing/types/billingTypes"
import { TPostCreateSubscriptionEstimateResponse } from "src/data/subscription/types/subscriptionTypes"
import { PLAN, TPlan } from "src/data/user/user"
import { Optional } from "src/utils/tsUtil"

export type TPlanWithCostEstimate = {
  availablePlan: TAvailablePlan
  cost: {
    plan: {
      quantity?: number
      unitPrice?: number
      total?: number
    }
    totalPrice: number
    estimate: TPostCreateSubscriptionEstimateResponse
  }
}

export function centsToDollarPerMonth(unitPrice: number) {
  // minimize number of division to avoid floating point error
  return unitPrice / (12 * 100)
}

export function createPlansWithCostEstimate({
  availablePlans,
  estimates,
}: {
  availablePlans: TAvailablePlan[]
  estimates: TPostCreateSubscriptionEstimateResponse[]
}): TPlanWithCostEstimate[] {
  const planIds = availablePlans.map((plan) => plan.plan_id)
  const planIdToAvailablePlan = availablePlans.reduce<{
    [key: string]: TAvailablePlan
  }>(
    (accumulator, plan) => ({
      ...accumulator,
      [plan.plan_id]: plan,
    }),
    {}
  )
  const entityIdToEstimate = estimates.reduce<{
    [key: string]: TPlanWithCostEstimate["cost"]
  }>((accumulator, estimate) => {
    const planLineItem = estimate.invoice_estimate?.line_items?.find(
      (item) => item.entity_type === LineItemEntityType.PLAN
    )

    if (planLineItem && planLineItem.entity_id) {
      return {
        ...accumulator,
        [planLineItem.entity_id]: {
          plan: {
            unitPrice: planLineItem.unit_amount,
            quantity: planLineItem.quantity,
            total: planLineItem.amount,
          },
          totalPrice: estimate.invoice_estimate?.total ?? 0,
          estimate: estimate,
        },
      }
    } else {
      throw new Error(
        "Could not find any plan Item or plan Item with entity_id in estimate"
      )
    }
  }, {})

  // @ts-expect-error: noUncheckedIndexedAccess
  return planIds.map((planId) => ({
    availablePlan: planIdToAvailablePlan[planId],
    cost: entityIdToEstimate[planId],
  }))
}

export function stringToPlan(plan: string | undefined): TPlan {
  if (plan === "pro") {
    return PLAN.pro
  } else if (plan === "pro_plus") {
    return PLAN.pro_plus
  } else if (plan === "standard") {
    return PLAN.standard
  }
  return PLAN.starter
}

export function formatUTCSecondsDate(date?: UTCSeconds) {
  return date ? format(new Date(Number(date) * 1000), "MMM do yyyy") : null
}

export function formatUTCStringDate(date?: string) {
  return date ? format(new Date(date), "MMM do yyyy") : null
}

export function getCouponIdsFromEstimate(estimate: Optional<TEstimate>) {
  if (!estimate) {
    return undefined
  }

  return (
    estimate.invoice_estimate?.discounts
      ?.filter((discount) =>
        ["document_level_coupon", "item_level_coupon"].includes(
          discount?.entity_type || ""
        )
      )
      ?.map((discount) => discount.entity_id)
      /** For all discounts of type document_level_coupon or item_level_coupon,
      chargebee returns an entity_id, which corresponds to the id of the
      coupon. This typeguard makes typescript understand this. */
      ?.filter((entityId): entityId is string => entityId !== undefined)
  )
}
